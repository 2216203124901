<template>
  <Modal
      v-model="addDataModal"
      title="关联试题"
      width="1000px"
      @on-cancel="cancel"
  >
    <div class="add-label">
      <div class="select">
        <p>已选择：</p>
        <div class="select-cont" v-if="selectDataList && selectDataList.length">
          <div v-for="item in selectDataList" :key="item.id">
            <div >
              <!--                  {{ item.name }}-->
              <Tooltip placement="top">
                <p style="padding-top:3px;width: 200px;overflow:hidden; text-overflow: ellipsis;white-space: nowrap;">{{ item.name }}</p>
                <div slot="content" style="white-space: normal;">
                  <p>{{ item.name }}</p>
                </div>
              </Tooltip>
            </div>
            <Icon type="md-close" color="#2d8cf0" @click="deleTitleSelectData(item)" />
          </div>
        </div>
        <div v-else>暂无数据</div>
      </div>
      <div class="list-cont">
        <div class="search">
          <div class="search-item">
            <div class="name">
              一级分类:
            </div>
            <div class="cont">
              <div class="values">
                <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="search-item" v-if="secondCategoryList.length > 1">
            <div class="name">
              二级分类:
            </div>
            <div class="cont">
              <div class="values" >
                <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="search-item" v-if="thirdCategoryList.length > 1">
            <div class="name">
              三级分类:
            </div>
            <div class="cont">
              <div class="values" >
                <div :class="curThirdCategoryId == item.id ? 'active' : ''" v-for="item in thirdCategoryList" :key="item.id + 'thirdCategory'" @click="changeSearch(item,'curThirdCategoryId')">{{item.name}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="label-search" style="margin-bottom:20px;display: flex;justify-content: flex-end">
          <Input v-model="dataKeyword"
                 :placeholder="$t('common_search_keyword')"
                 clearable
                 search
                 :enter-button="true"
                 @on-search="changeDataKeyword"
                 class="keyword-search"
                 style="width: 300px"
          />
        </div>
        <Table border ref="selection" :loading="dataLoading" :columns="dataColumns" :data="dataList" @on-select="selectAddData" @on-select-all="selectAddData" @on-select-cancel="selectDeleData" @on-select-all-cancel="selectDeleData"></Table>
      </div>
    </div>
    <div class="page">
      <Page :total="dataTotal" :page-size="dataPageSize" @on-change="changeDataPage" @on-page-size-change="changeDataPageSize" :current="dataPage" show-total show-sizer show-elevator></Page>
    </div>

    <div slot="footer">
      <Button @click="cancel">{{ $t('dataset_map_create_cancel') }}</Button>
      <Button type="primary" @click="confirmAddData">{{ $t('dataset_map_create_confirm') }}</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "addData",
  data(){
    return{
      addDataModal:true,
      dataColumns:[
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '试题ID',
          key:'id',
          width: 120,
        },
        {
          title: this.$t('dataset_data_table_title'),
          key: 'title',
        },
      ],
      dataList:[],
      dataKeyword:'',
      dataPage:1,
      dataTotal:0,
      dataPageSize:10,
      dataLoading:false,
      cacheSelectData:{},
      selectModalDataList:[],
      selectDataList:[],
      categoryList:[],  //一级分类
      curCategoryId:'-1', //当前分类
      secondCategoryList:[],  //二级分类
      cursecondCategoryId:'-1', //当前二级分类
      curThirdCategoryId:'-1',//当前三级分类
      thirdCategoryList:[],
    }
  },
  created(){
    this.getCategoryList();
    this.getList();
  },
  methods:{
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        category_second_id:this.cursecondCategoryId,
        site_id:-1,
        user_id:-1,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        //二级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];
        console.log(this.secondCategoryList,'this.secondCategoryList')
        this.thirdCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryThirdList
        ]
      })
    },
    changeSearch(data,name){
      if(name == 'curCategoryId'){
        this.cursecondCategoryId = '-1';
        this.curThirdCategoryId = '-1';
      }else if(name == 'cursecondCategoryId'){
        this.curThirdCategoryId = '-1';
      }
      this[name] = data.id;
      this.page = 1;
      this.getCategoryList();
      this.getList();
    },
    changeDataKeyword(){
      this.dataPage = 1;
      this.getList();
    },
    deleTitleSelectData(data){
      let ind = this.selectDataList.findIndex((sItem)=>{
        return data.id == sItem.id;
      })
      this.$delete(this.selectDataList,ind);

      this.getList();
    },
    getList(){
      this.dataLoading = true;

      let params = {
        site_id:-1,
        user_id:-1,
        page:this.dataPage,
        pageSize:this.dataPageSize,
        keyword:this.dataKeyword,
        category_id: this.categoryId
      };
      if(this.curThirdCategoryId && this.curThirdCategoryId != -1){
        params.category_id = this.curThirdCategoryId;
      }else if(this.cursecondCategoryId && this.cursecondCategoryId != -1){
        params.category_id = this.cursecondCategoryId;
      }else if(this.curCategoryId && this.curCategoryId != -1){
        params.category_id = this.curCategoryId;
      }

      this.api.dataset.dataList(params).then((res)=>{
        this.dataLoading = false;

        this.dataList = res.list;
        this.dataTotal = Number(res.count);

        this.dataList.forEach((item)=>{

          let len = this.selectDataList.filter((sItem)=>{
            return sItem.id == item.id;
          });

          if(len && len.length){
            item._checked = true;
          }else{
            item._checked = false;
          }
        })
      }).catch((err)=>{
        this.dataLoading = false;
      })
    },
    changeDataPage(page){
      this.dataPage = page;
      this.getList();
    },
    changeDataPageSize(pageSize){
      this.dataPage = 1;
      this.dataPageSize = pageSize;
      this.getList();
    },
    selectAddData(data,row){
      console.log(data,'dddd')
      if(row){  //单独增加
        this.selectDataList.push(row);
      }else{  //全选增加
        data.forEach((item)=>{
          let len = this.selectDataList.filter((sItem)=>{
            return sItem.id == item.id;
          })
          if(!len.length){
            this.selectDataList.push(item);
          }
        })
      }
    },
    selectDeleData(data,row){
      if(row){  //单独取消
        let ind = this.selectDataList.findIndex((sItem)=>{
          return sItem.id == row.id;
        })
        this.$delete(this.selectDataList,ind);
      }else{  //全选取消
        this.dataList.forEach((item)=>{
          let ind = this.selectDataList.findIndex((sItem)=>{
            return sItem.id == item.id;
          })
          if(ind != -1){
            this.$delete(this.selectDataList,ind);
          }
        })
      }
    },
    confirmAddData(){

      let ids = this.selectDataList.map((item)=>{
        return item.id;
      }).join(',');

      this.$emit('confirmAdd',ids)
    },
    cancel(){
      this.$emit('cancelAdd')
    }
  }
}
</script>

<style scoped lang="scss">
.add-label{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .select{
    padding: 20px 20px 10px 20px;
    border: 1px solid #dcdee2;

    >p{
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
    }

    .select-cont{

      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      >div{
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 0 10px;
        border: 1px solid #2d8cf0;
        height: 40px;
        border-radius: 4px;
        text-align: center;
        display: flex;
        justify-content: space-between;
        align-items: center;

        >p{
          margin-right: 10px;
          color:#2d8cf0;
        }
      }
    }
  }
  .list-cont{
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #dcdee2;

    .label-search{
      display: flex;
      justify-content: flex-end;
    }
    .keyword-search{
      margin-bottom: 20px;
      width: 300px;
    }
  }
  .list{

    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    >div{
      margin: 0 10px 10px 0;
      padding: 0 10px;
      border: 1px solid #dcdee2;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
    .active{
      border: 1px solid #2d8cf0;
      color:#2d8cf0;
    }
  }
}
.page{
  margin-top: 20px;
  text-align: right;
}
</style>
