<template>
<div class="data-list">
  <div class="top">
    <div class="top-left">
      <Button type="primary" @click="addData">关联题目</Button>
    </div>
    <div class="top-right">
      <Input v-model="keyword"
             :placeholder="$t('common_search_keyword')"
             clearable
             search
             :enter-button="true"
             @on-enter="changeKeyword"
             @on-search="changeKeyword"
      />
    </div>
  </div>
  <Table border :columns="columns" :loading="loading" :data="data"></Table>
  <div class="page">
    <Page :total="total" @on-change="changePage" @on-page-size-change="changePageSize" :current="page" show-total show-sizer show-elevator></Page>
  </div>
  <DeleModal :status="deleModalStatus" txt="确认删除关联吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

  <div v-if="addDataModal"><AddData @confirmAdd="confirmAdd" @cancelAdd="cancelAdd"></AddData></div>
</div>
</template>

<script>
import DeleModal from '@/components/deleteModal.vue';
import AddData from '../common/addData.vue';
export default {
  name: "dataList",
  data(){
    return {
      columns:[
        {
          title: 'ID',
          key:'data_id',
          width:100,
          render:(h,params)=>{
            return h('p', {
              style:{
                color:'#2db7f5',
                cursor:'pointer'
              },
              on:{
                click:()=>{
                  const routeUrl = this.$router.resolve({
                    name: 'data-detail',
                    params: {
                      id: params.row.data.id,
                      tab:'cont'
                    }
                  })
                  window.open(routeUrl.href, "_blank");
                }
              }
            }, params.row.data_id);
          }
        },
        {
          title: '题目名称',
          // key:'name',
          render:(h,params)=>{
            return h('span',params.row.data && params.row.data.name)
          }
        },
        {
          title: '操作',
          width:140,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style: {
                  marginRight: '5px',
                  marginTop: '5px'
                },
                on: {
                  click: () => {
                    this.deleteData(params.row);
                  }
                }
              }, '删除关联'),
            ]);
          }
        },

      ],
      data:[],
      loading:false,
      total:0,
      page:1,
      pageSize:10,
      labelId:'',
      deleModalStatus:false,
      modalLoading:false,
      curData:{},
      keyword:'',
      addDataModal:false,
    }
  },
  components:{
    DeleModal,
    AddData
  },
  created(){
    this.labelId = this.$route.params.id;
    this.getList();
  },
  methods:{
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){
      let params = {
        label_id:this.labelId,
        keyword:this.keyword,
        page:this.page,
        pageSize:this.pageSize
      };
      this.loading = true;
      this.api.dataset.labelDataList(params).then((res)=>{
        this.loading = false;
        if(this.page > 1 && !res.list.length){
          this.getList();
        }else{
          this.data = res.list;
          this.total = Number(res.count);
        }
      }).catch((err)=>{
        this.loading = false;
      })
    },
    deleteData(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    confirmDele(){
      let params = {
        label_id:this.labelId,
        data_id:this.curData.data_id
      };
      this.api.dataset.labelDataDel(params).then((res)=>{
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.loading = false;
      })
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    confirmAdd(data){
      let params = {
        label_id:this.labelId,
        data_id:data,
      };
      this.api.dataset.labelDataAdd(params).then((res)=>{
        this.$Message.success('关联成功');
        this.addDataModal = false;
        this.getList();
      })
    },
    addData(){
      this.addDataModal = true;
    },
    cancelAdd(){
      this.addDataModal = false;
    }
  }

}
</script>

<style scoped lang="scss">
.data-list{
  .top{
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-right{
      width: 200px;
      text-align: right;
    }
  }
  .page{
    text-align: right;
    margin-top: 20px;
  }
}

</style>
